// 라이브러리
import { useState, useEffect } from 'react'
import styled from 'styled-components'
// 리덕스

// 컴포넌트
import Private240410 from './Private240410'
import Private240624 from './Private240624'
import Private240806 from './Private240806'

import CaboInner from '../../../components/CaboInner'
import CaboText from '../../../components/CaboText'
import CaboSpacer from '../../../components/CaboSpacer'
// 전역 API

// 공통변수

// 기타

const Private = () => {
  const [selectId, setSelectId] = useState(null)
  const content = [<Private240410 />, <Private240624 />, <Private240806 />]
  const [showContent, setShowContent] = useState(null)

  useEffect(() => {
    setShowContent(content[selectId])
  }, [selectId])

  return (
    <>
      {selectId === null ? (
        <>
          <div
            style={{ width: 840, height: 3, backgroundColor: '#16223B' }}
          ></div>
          <div
            style={{
              width: 840,
              height: 60,
              backgroundColor: '#FCFCFC',
              display: 'grid',
              gridTemplateColumns: '3fr 1fr 1fr',
            }}
          >
            <TableTitle>
              <CaboText fontSize={18} fontWeight="500">
                제 목
              </CaboText>
            </TableTitle>
            <TableTitle>
              <CaboText fontSize={18} fontWeight="500">
                다운로드
              </CaboText>
            </TableTitle>
            <TableTitle>
              <CaboText fontSize={18} fontWeight="500">
                등록일
              </CaboText>
            </TableTitle>
          </div>
          <div style={{ width: 840, height: 1, backgroundColor: '#D3D3D3' }} />
          <div
            style={{
              width: 840,
              height: 60,
              backgroundColor: '#fff',
              display: 'grid',
              gridTemplateColumns: '3fr 1fr 1fr ',
            }}
          >
            <TableTitle>
              <CaboText
                fontSize={16}
                fontWeight="400"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectId(2)
                }}
              >
                개인정보 처리방침(2024.08.06)
              </CaboText>
            </TableTitle>
            <TableTitle>
              <div
                fontSize={16}
                fontWeight="400"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const link = document.createElement('a')
                  link.href =
                    process.env.PUBLIC_URL + '/개인 정보 처리 방침_240806.hwpx'
                  link.download = '개인 정보 처리 방침_240806.hwpx'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }}
              >
                <img src="/img/download.svg" alt="다운로드" />
              </div>
            </TableTitle>
            <TableTitle>
              <CaboText fontSize={16} fontWeight="400">
                2024.08.06
              </CaboText>
            </TableTitle>
          </div>
          <div style={{ width: 840, height: 1, backgroundColor: '#D3D3D3' }} />
          <div
            style={{
              width: 840,
              height: 60,
              backgroundColor: '#fff',
              display: 'grid',
              gridTemplateColumns: '3fr 1fr 1fr ',
            }}
          >
            <TableTitle>
              <CaboText
                fontSize={16}
                fontWeight="400"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectId(1)
                }}
              >
                개인정보 처리방침(2024.06.24)
              </CaboText>
            </TableTitle>
            <TableTitle>
              <div
                fontSize={16}
                fontWeight="400"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const link = document.createElement('a')
                  link.href =
                    process.env.PUBLIC_URL + '/개인 정보 처리 방침_240624.hwpx'
                  link.download = '개인 정보 처리 방침_240624.hwpx'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }}
              >
                <img src="/img/download.svg" alt="다운로드" />
              </div>
            </TableTitle>
            <TableTitle>
              <CaboText fontSize={16} fontWeight="400">
                2024.06.24
              </CaboText>
            </TableTitle>
          </div>
          <div style={{ width: 840, height: 1, backgroundColor: '#D3D3D3' }} />
          <div
            style={{
              width: 840,
              height: 60,
              backgroundColor: '#fff',
              display: 'grid',
              gridTemplateColumns: '3fr 1fr 1fr ',
            }}
          >
            <TableTitle>
              <CaboText
                fontSize={16}
                fontWeight="400"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectId(0)
                }}
              >
                개인정보 처리방침(2024.04.10)
              </CaboText>
            </TableTitle>
            <TableTitle>
              <div
                fontSize={16}
                fontWeight="400"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const link = document.createElement('a')
                  link.href =
                    process.env.PUBLIC_URL + '/개인 정보 처리 방침_240410.hwpx'
                  link.download = '개인 정보 처리 방침_240410.hwpx'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }}
              >
                <img src="/img/download.svg" alt="다운로드" />
              </div>
            </TableTitle>
            <TableTitle>
              <CaboText fontSize={16} fontWeight="400">
                2024.04.10
              </CaboText>
            </TableTitle>
          </div>
          <div style={{ width: 840, height: 1, backgroundColor: '#D3D3D3' }} />
        </>
      ) : (
        <CaboInner>
          <div
            style={{ width: 840 }}
            onClick={() => {
              setSelectId(null)
            }}
          >
            <img
              src="/img/backArrow.svg"
              alt="뒤로가기"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <CaboSpacer space={20} />
          <div style={{ width: 840, height: 3, backgroundColor: '#16223B' }} />
          <div
            style={{
              width: 840,
              height: '100%',
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CaboSpacer space={20} />
            {showContent}
            <CaboSpacer space={20} />
          </div>
          <div style={{ width: 840, height: 3, backgroundColor: '#16223B' }} />
        </CaboInner>
      )}
    </>
  )
}
export default Private

const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
