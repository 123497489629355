// 라이브러리
import { useState, useEffect } from 'react'
import styled from 'styled-components'
// 리덕스

// 컴포넌트
import Net240806 from './Net240806'

import CaboInner from '../../../components/CaboInner'
import CaboText from '../../../components/CaboText'
import CaboSpacer from '../../../components/CaboSpacer'
// 전역 API

// 공통변수

// 기타

const Net = () => {
  const [selectId, setSelectId] = useState(null)
  const content = [<Net240806 />]
  const [showContent, setShowContent] = useState(null)

  useEffect(() => {
    setShowContent(content[selectId])
  }, [selectId])
  return (
    <>
      {selectId === null ? (
        <>
          <div
            style={{ width: 840, height: 3, backgroundColor: '#16223B' }}
          ></div>
          <div
            style={{
              width: 840,
              height: 60,
              backgroundColor: '#FCFCFC',
              display: 'grid',
              gridTemplateColumns: '4fr 1fr',
            }}
          >
            <TableTitle>
              <CaboText fontSize={18} fontWeight="500">
                제 목
              </CaboText>
            </TableTitle>
            <TableTitle>
              <CaboText fontSize={18} fontWeight="500">
                등록일
              </CaboText>
            </TableTitle>
          </div>
          <div style={{ width: 840, height: 1, backgroundColor: '#D3D3D3' }} />
          <div
            style={{
              width: 840,
              height: 60,
              backgroundColor: '#fff',
              display: 'grid',
              gridTemplateColumns: '4fr 1fr ',
            }}
          >
            <TableTitle>
              <CaboText
                fontSize={16}
                fontWeight="400"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectId(0)
                }}
              >
                개인정보 처리방침 변경 안내
              </CaboText>
            </TableTitle>
            <TableTitle>
              <CaboText fontSize={16} fontWeight="400">
                2024.08.06
              </CaboText>
            </TableTitle>
          </div>
          <div
            style={{ width: 840, height: 1, backgroundColor: '#D3D3D3' }}
          ></div>
        </>
      ) : (
        <CaboInner>
          <div
            style={{ width: 840 }}
            onClick={() => {
              setSelectId(null)
            }}
          >
            <img
              src="/img/backArrow.svg"
              alt="뒤로가기"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <CaboSpacer space={20} />
          <div style={{ width: 840, height: 3, backgroundColor: '#16223B' }} />
          <div
            style={{
              width: 840,
              height: '100%',
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {showContent}
            <CaboSpacer space={20} />
          </div>
          <div style={{ width: 840, height: 3, backgroundColor: '#16223B' }} />
        </CaboInner>
      )}
    </>
  )
}
export default Net

const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
