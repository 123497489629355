// 라이브러리

// 리덕스

// 컴포넌트

// 전역 API

// 공통변수

// 기타

const Net240806 = () => {
  return (
    <pre
      style={{
        fontSize: 16,
        whiteSpace: 'pre-wrap',
        lineHeight: 1.3,
      }}
    >{`
주식회사 카보에서는 개인정보 이용 및 보호 조치 개선에 대한 보다 자세한 정보를 제공하기 위하여 개정한 개인정보처리방침의 시행일정을 안내드립니다. 변경 내용 및 시행예정일은 다음과 같으며 자세한 내용은 첨부된 파일을 다운로드받아 참고하시기 바랍니다.

- 대상 : 개인정보처리방침
- 공고일 : 2024년 08월 06일
- 시행일 : 2024년 08월 13일
`}</pre>
  )
}
export default Net240806
