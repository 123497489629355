// 라이브러리

// 리덕스

// 컴포넌트

// 전역 API

// 공통변수

// 기타

const Private240410 = () => {
  return (
    <pre
      style={{
        fontSize: 14,
        whiteSpace: 'pre-wrap',
        lineHeight: 1.3,
      }}
    >{`
'개인 정보 처리 방침

<㈜카보> (이하 “다이렉트 카보”)은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립  공개합니다.

이 개인정보처리방침은 2024년 4월 10일부터 적용됩니다.


제 1조(개인정보의 처리 목적)

다이렉트 카보는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

1. 서비스 제공

서비스 제공, 콘텐츠 제공, 맞춤서비스 제공을 목적으로 개인정보를 처리합니다.


제 2조(개인정보의 처리 및 보유 기간)

① 다이렉트 카보는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.

② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.

1. 다이렉트자동차 보험료 확인 요청과 관련한 개인정보는 수집.이용에 관한 동의일로부터까지 위 이용목적을 위하여 보유, 이용됩니다.

보유근거: 서비스 제공 완료 및 만기 안내

기한: 동의일로부터 5년 

예외사유 : 소비자가 원할 시 즉시처리


제 3조(개인정보의 제 3자 제공)

다이렉트 카보는 개인을 특정할수 없는 정보만 제 3자에게 제공 됩니다.

제4조(정보 주체와 법정 대리인의 권리·의무 및 그 행사 방법)


① 정보 주체는 다이렉트 카보에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.

② 제1항에 따른 권리 행사는 주식회사 다이렉트 카보에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 다이렉트 카보는 이에 대해 지체 없이 조치하겠습니다.

③ 제1항에 따른 권리 행사는 정보 주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.

④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보 주체의 권리가 제한될 수 있습니다.

⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.

⑥ 다이렉트 카보는 정보 주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.


제 5조(처리하는 개인정보의 항목 작성)

① 다이렉트 카보는 다음의 개인정보 항목을 처리하고 있습니다.

1. 재화 또는 서비스의 제공을 위함

필수 항목 : 신청자 이름, 주민등록번호, 신청자 연락처, 차량번호, 기가입보험사명, 자동차보험 만기일자, 12개 손해보험사의 자동차 보험료, 자동차 보험 설계항목


제 6조(개인 정보의 파기)

① 다이렉트 카보는 개인정보 보유 기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때는 바로 해당 개인정보를 파기합니다.

② 정보 주체로부터 동의받은 개인정보 보유 기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.

③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.

1. 파기 절차

다이렉트 카보는 파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호 책임자의 승인을 받아 개인정보를 파기합니다.

2. 파기 방법

전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.

종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다


제 7조(개인정보의 안전성 확보 조치)

다이렉트카보는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 하고 있습니다.

1. 내부관리계획의 수립 및 시행

개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 수행하고 있습니다.

2. 개인정보에 대한 접근 제한

개인정보를 처리하는 데이터베이스시스템에 대한 접근 권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입 차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.


제8조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)

다이렉트 카보는 사용자가 거부 희망 시 바로 수집 정보를 삭제합니다.


제9조(권익침해 구제 방법)

다이렉트카보는 사용자를 특정할 수 있는 개인정보를 제 3자에게 넘기지 않으며, 서비스 제공 이외의 목적으로 정보를 이용하지 않습니다.


제 10조(권익침해 구제방법)

정보 주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보 분쟁 조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.

1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)

2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)

3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)

4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)

「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.

※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.


제11조(개인정보 처리방침 변경)

① 이 개인정보처리방침은 2024년 4월 10일부터 적용됩니다.
`}</pre>
  )
}
export default Private240410
