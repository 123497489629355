import forge from 'node-forge'
import axios from 'axios'

let publicKey = ''

export const encryptData = async (data) => {
  if (!publicKey) {
    publicKey = (
      await axios.get(
        `${process.env.REACT_APP_CABO_BACKEND}${process.env.REACT_APP_GET_PUBLIC_KEY}`,
      )
    ).data.publicKey
  }
  const publicKeyFromPem = forge.pki.publicKeyFromPem(publicKey)
  const symmetricKey = forge.random.getBytesSync(16)
  const iv = forge.random.getBytesSync(16)
  const cipher = forge.cipher.createCipher('AES-CBC', symmetricKey)

  cipher.start({ iv: iv })
  cipher.update(
    forge.util.createBuffer(forge.util.encodeUtf8(JSON.stringify(data))),
  )
  cipher.finish()

  const encryptedData = cipher.output.getBytes()
  const encryptedSymmetricKey = publicKeyFromPem.encrypt(
    symmetricKey,
    'RSA-OAEP',
  )

  return {
    encryptedData: forge.util.encode64(encryptedData),
    encryptedKey: forge.util.encode64(encryptedSymmetricKey),
    iv: forge.util.encode64(iv),
  }
}
