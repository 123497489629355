import styled from 'styled-components'
// 라이브러리

// 리덕스

// 컴포넌트
import CaboInner from '../../../components/CaboInner'
import CaboText from '../../../components/CaboText'
import CaboSpacer from '../../../components/CaboSpacer'
// 전역 API

// 공통변수

// 기타

const Insu = () => {
  return (
    <>
      <div style={{ width: 840, height: 3, backgroundColor: '#16223B' }}></div>
      <div
        style={{
          width: 840,
          height: 60,
          backgroundColor: '#FCFCFC',
          display: 'grid',
          gridTemplateColumns: '3fr 1fr 1fr',
        }}
      >
        <TableTitle>
          <CaboText fontSize={18} fontWeight="500">
            제 목
          </CaboText>
        </TableTitle>
        <TableTitle>
          <CaboText fontSize={18} fontWeight="500">
            다운로드
          </CaboText>
        </TableTitle>
        <TableTitle>
          <CaboText fontSize={18} fontWeight="500">
            등록일
          </CaboText>
        </TableTitle>
      </div>
      <div style={{ width: 840, height: 1, backgroundColor: '#D3D3D3' }} />
      <div
        style={{
          width: 840,
          height: 60,
          backgroundColor: '#fff',
          display: 'grid',
          gridTemplateColumns: '3fr 1fr 1fr ',
        }}
      >
        <TableTitle>
          <CaboText fontSize={16} fontWeight="400">
            2024년 상반기 공시
          </CaboText>
        </TableTitle>
        <TableTitle>
          <div
            fontSize={16}
            fontWeight="400"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const link = document.createElement('a')
              link.href =
                process.env.PUBLIC_URL +
                '/주식회사 카보 법인 손해보험중개사 공시 자료.pdf'
              link.download = '주식회사 카보 법인 손해보험중개사 공시 자료.pdf'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }}
          >
            <img src="/img/download.svg" alt="다운로드" />
          </div>
        </TableTitle>
        <TableTitle>
          <CaboText fontSize={16} fontWeight="400">
            2024.07.30
          </CaboText>
        </TableTitle>
      </div>
      <div style={{ width: 840, height: 1, backgroundColor: '#D3D3D3' }} />
    </>
  )
}
export default Insu

const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
