// 라이브러리
import styled from 'styled-components'
// 리덕스

// 컴포넌트

// 전역 API

// 공통변수

// 기타

const Private240806 = () => {
  return (
    <div style={{ padding: '40px 20px' }}>
      <Title>주식회사 카보 개인정보 처리방침 (Ver.1.3)</Title>
      <div style={{ marginTop: 30 }} />
      <a
        href="https://cabo.kr/pc/company/alarmBoard"
        style={{ display: 'block', textAlign: 'right' }}
      >
        이전 개인정보 처리방침 확인
      </a>
      <div style={{ marginTop: 50 }} />
      <Content>
        주식회사 카보(이하 ‘카보’ 또는 ‘회사)는 서비스 기획부터 종료까지
        개인정보보호법 국내의 개인정보 보호 법령을 철저히 준수합니다.
      </Content>
      <div style={{ marginTop: 30 }} />
      <div style={{ border: 'solid 1px #000', padding: 10 }}>
        <Content>본 개인정보처리방침의 목차는 아래와 같습니다.</Content>
        <Content>
          관계법령이 요구하는 개인정보 처리방침의 필수 사항과 카보 자체적으로
          이용자 개인정보 보호에 있어 중요하게 판단하는 내용을 포함하였습니다.
        </Content>
        <div style={{ border: 'solid 1px #000', margin: '10px 0' }} />
        <Content>1. 개인정보처리방침의 의의</Content>
        <Content>2. 수집하는 개인정보 </Content>
        <Content>3. 수집한 개인정보의 이용 </Content>
        <Content>4. 개인정보의 제공 및 위탁 </Content>
        <Content>5. 개인정보의 파기 </Content>
        <Content>6. 이용자 및 법정대리인의 권리와 행사 방법</Content>
        <Content>7. 개인정보보호를 위한 카보의 노력 </Content>
        <Content>8. 개인정보 보호책임자 및 담당자 안내</Content>
        <Content>9. 본 개인정보처리방침의 적용 범위</Content>
        <Content>10. 개정 전 고지 의무</Content>
      </div>
      <div style={{ marginTop: 50 }} />

      <div style={{ borderTop: 'solid 5px #000', padding: '50px 0' }}>
        <Title>1. 개인정보 처리방침의 의의</Title>
        <div style={{ marginTop: 30 }} />
        <ContentBold>
          카보는 본 개인정보처리방침을 개인정보보호법을 기준으로 작성하되, 카보
          내에서의 이용자 개인정보 처리 현황을 최대한 알기 쉽고 상세하게
          설명하기 위해 노력하였습니다.
        </ContentBold>
        <div style={{ marginTop: 30 }} />
        <Content>
          개인정보처리방침은 다음과 같은 중요한 의미를 가지고 있습니다.
        </Content>
        <div style={{ marginTop: 15 }} />
        <Text>
          - 카보가 어떤 정보를 수집하고, 수집한 정보를 어떻게 사용하며, 필요에
          따라 누구와 이를 공유(위탁 또는 제공)하며, 이용 목적을 달성한 정보를
          언제, 어떻게 파기하는지 등 “개인정보의 한살이”와 관련한 정보를
          투명하게 제공합니다.
        </Text>
        <Text>
          - 정보주체로서 이용자는 개인정보에 대해 어떤 권리를 가지고 있으며,
          이를 어떤 방법과 절차로 행사할 수 있는지를 알려드립니다. 또한,
          법정대리인(부모 등)이 만 14세미만 아동의 개인정보 보호를 위해 어떤
          권리를 행사할 수 있는지도 함께 안내합니다.
        </Text>
        <Text>
          - 개인정보 침해사고가 발생하는 경우, 추가적인 피해를 예방하고 이미
          발생한 피해를 복구하기 위해 누구에게 연락하여 어떤 도움을 받을 수
          있는지 알려드립니다.
        </Text>
      </div>

      <div style={{ borderTop: 'solid 5px #000', padding: '50px 0' }}>
        <Title>2. 수집하는 개인정보</Title>
        <div style={{ marginTop: 30 }} />
        <ContentBold>
          카보는 자동차보험료 비교 서비스를 제공하고 있습니다. 서비스의 제공을
          위해 본인인증 시 일부 개인정보를 수집하고 있으며, 서비스를 이용하지
          않는 경우, 개인정보를 수집하지 않습니다.
        </ContentBold>
        <div style={{ marginTop: 30 }} />
        <ContentBold>
          서비스 이용 시 카보가 이용자로부터 수집하는 개인정보는 아래와
          같습니다.
        </ContentBold>
        <CustomTable>
          <tr>
            <CustomTd style={{ width: 'calc(100% / 3)' }}>
              <ContentBold>수집항목</ContentBold>
            </CustomTd>
            <CustomTd style={{ width: 'calc(100% / 3)' }}>
              <ContentBold>수집 및 이용 목적</ContentBold>
            </CustomTd>
            <CustomTd style={{ width: 'calc(100% / 3)' }}>
              <ContentBold>보유 및 이용 기간</ContentBold>
            </CustomTd>
          </tr>
          <tr>
            <CustomTd style={{ width: 'calc(100% / 3)' }}>
              <Content style={{ textAlign: 'left' }}>
                (필수) 이름, 휴대폰번호, 휴대폰 통신사, 생년월일, 성별
              </Content>
              <div style={{ marginTop: 20 }} />
              <Content style={{ textAlign: 'left' }}>
                기존가입 보험계약 정보 및 희망가입 보험계약 정보 (자동차 번호,
                가입보험사, 보험만기일자, 보험계약번호, 보험종목, 특약사항,
                보장설정 사항)
              </Content>
            </CustomTd>
            <CustomTd style={{ width: 'calc(100% / 3)' }}>
              <Content style={{ fontSize: 20 }}>
                자동차 보험료 비교 조회
              </Content>
            </CustomTd>
            <CustomTd style={{ width: 'calc(100% / 3)' }}>
              <Title>동의일로부터 2개월 후 삭제</Title>
            </CustomTd>
          </tr>
        </CustomTable>
        <ContentBold>
          서비스 이용 과정에서 IP주소, 쿠키, 서비스 이용 기록이 생성되어 수집될
          수 있습니다.
        </ContentBold>
      </div>

      <div style={{ borderTop: 'solid 5px #000', padding: '50px 0' }}>
        <Title>3. 수집한 개인정보의 이용</Title>
        <div style={{ marginTop: 30 }} />
        <ContentBold>
          카보는 제반 서비스(다이렉트 카보)의 서비스 개발 및 제공 향상, 안전한
          인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.
        </ContentBold>
        <div style={{ marginTop: 30 }} />
        <Text>
          - 인구통계학적 분석, 서비스 방문 및 이용기록의 분석 등 기존 서비스
          개선 등을 위하여 개인정보를 이용합니다.
        </Text>
        <Text>
          - 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을
          위하여 개안정보를 이용합니다.
        </Text>
        <Text>- 보험료 비교 서비스 제공을 위하여 개인정보를 이용합니다.</Text>
        <Text>
          - 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는
          서비스 이용환경 구축을 위해 개인정보를 이용합니다.
        </Text>
      </div>

      <div style={{ borderTop: 'solid 5px #000', padding: '50px 0' }}>
        <Title>4. 개인정보의 제공 및 위탁</Title>
        <div style={{ marginTop: 30 }} />
        <ContentBold>
          카보는 원칙적으로 이용자의 동의 없이 개인정보를 외부에 제공하지
          않습니다.
        </ContentBold>
        <div style={{ marginTop: 30 }} />
        <Content>
          카보는 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다.
          단, 이용자가 다이렉트 자동차보험 비교 서비스
          <span style={{ fontWeight: 'bold' }}>
            (보험중개사 카보가 온라인 상에서 중개를 하는 서비스)
          </span>
          를 이용하기 위하여 개인정보 제공에 직접 동의를 한 경우에 한하여
          개인정보를 제공하고 있습니다.
        </Content>
        <div style={{ marginTop: 30 }} />
        <ContentBold>
          카보는 다이렉트 자동차보험료 비교 서비스를 제공하기 위해 필수적으로
          필요한 정보만 제3자에게 아래와 같이 제공하고 있습니다.
        </ContentBold>
        <CustomTable>
          <tr>
            <CustomTd style={{ width: 'calc(100% / 4)' }}>
              <ContentBold>개인정보를 제공받는 자</ContentBold>
            </CustomTd>
            <CustomTd style={{ width: 'calc(100% / 4)' }}>
              <ContentBold>제공받는 자의 개인정보 이용 목적</ContentBold>
            </CustomTd>
            <CustomTd style={{ width: 'calc(100% / 4)' }}>
              <ContentBold>제공하는 개인정보 항목</ContentBold>
            </CustomTd>
            <CustomTd style={{ width: 'calc(100% / 4)' }}>
              <ContentBold>제공받는 자의 보유 및 이용 기간</ContentBold>
            </CustomTd>
          </tr>
          <tr>
            <CustomTd style={{ width: 'calc(100% / 4)' }}>
              <Title>손해보험협회 (보험 다모아)</Title>
            </CustomTd>
            <CustomTd style={{ width: 'calc(100% / 4)' }}>
              <Title>자동차 보험료 비교 조회 </Title>
            </CustomTd>
            <CustomTd style={{ width: 'calc(100% / 4)' }}>
              <Content>
                (필수) 이름, 주민등록번호 휴대폰 번호 휴대폰 통신사
              </Content>
            </CustomTd>
            <CustomTd style={{ width: 'calc(100% / 4)' }}>
              <Title>조회 완료 후 2개월 까지</Title>
            </CustomTd>
          </tr>
        </CustomTable>
        <div style={{ marginTop: 20 }} />
        <ContentBold>
          다이렉트 카보는 주민등록번호를 저장하지 않으며, 보험 다모아측으로 직접
          전송됩니다.
        </ContentBold>
      </div>

      <div style={{ borderTop: 'solid 5px #000', padding: '50px 0' }}>
        <Title>5. 개인정보의 파기</Title>
        <div style={{ marginTop: 30 }} />
        <ContentBold>
          회사는 원칙적으로 이용자의 개인정보를 이용목적 달성 시 지체없이
          파기하고 있습니다.
        </ContentBold>
        <div style={{ marginTop: 30 }} />
        <Content>
          단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻는 경우, 또는
          법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안
          개인정보를 안전하게 보관합니다.
        </Content>
        <div style={{ marginTop: 30 }} />
        <ContentBold>
          이용자에게 개인정보 보관기간에 대해 서비스 이용 시 동의를 얻는 경우는
          아래와 같습니다.
        </ContentBold>
        <div style={{ marginTop: 30 }} />
        <Content>
          보험료 비교 서비스 제공을 위한 본인 확인 및 보험정보 확인{' '}
        </Content>
        <div style={{ marginTop: 10 }} />
        <Content>
          - 이름, 휴대폰번호, 통신사, 생년월일, 성별, 자동차번호, 기존가입
          보험계약 정보 및 희망가입 보험계약 정보: 동의일로부터 2개월
        </Content>

        <div style={{ marginTop: 30 }} />
        <ContentBold>
          서비스 종료, 이용자에게 동의받은 개인정보 보유기간의 도래와 같이
          개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한
          방법으로 파기하고 있습니다.
        </ContentBold>
        <div style={{ marginTop: 30 }} />
        <Content>
          전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을
          이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식
          등으로 파기합니다.
        </Content>
      </div>

      <div style={{ borderTop: 'solid 5px #000', padding: '50px 0' }}>
        <Title>6. 이용자 및 법정대리인의 권리와 행사 방법</Title>
        <div style={{ marginTop: 30 }} />
        <Text>
          - 이용자는 언제든지 “대표상담 번호 문의(044-715-7442)”를 통해 자신의
          개인정보를 조회하거나 수정, 삭제할 수 있으며, 자신의 개인정보에 대한
          열람을 요청할 수 있습니다, 또한 개인정보의 수집 및 이용 동의 철회가
          가능합니다.
        </Text>
        <Text>
          - 이용자는 언제든지 개인정보 처리의 정지를 요청할 수 있으며, 법률에
          특별한 규정이 있는 등의 경우에는 처리정지 요청을 거부할 수 있습니다
        </Text>
        <Text>
          - 만 14세 미만 아동의 경우, 법정대리인이 아동의 개인정보를 조회하거나
          수정 및 삭제, 처리정지, 수집 이용 동의를 철회할 권리를 가집니다.
        </Text>
        <Text>
          - 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기
          전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
          개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게
          지체없이 통지하여 정정이 이루어지도록 하겠습니다.
        </Text>
        <Text>
          - 이용자 및 법정대리인의 권리는 “대표상담 번호 문의(044-715-7442)”를
          통해 요청할 수 있습니다.
        </Text>
      </div>

      <div style={{ borderTop: 'solid 5px #000', padding: '50px 0' }}>
        <Title>7. 개인정보보호를 위한 카보의 노력</Title>
        <div style={{ marginTop: 30 }} />
        <ContentBold>
          카보는 이용자의 개인정보를 안전하게 관리하기 위하여 최선을 다하며,
          개인정보를 보호하고 있습니다.
        </ContentBold>
        <div style={{ marginTop: 30 }} />
        <Content>
          개인정보보호 내부 관리계획을 수립 및 시행하고 있습니다.
        </Content>
        <div style={{ marginTop: 15 }} />
        <Content>
          개인정보에 대한 접근 통제 및 접근 권한 제한 조치를 하고 있습니다.
        </Content>
        <div style={{ marginTop: 15 }} />
        <Content>
          개인정보를 안전하게 저장, 전송할 수 있는 암호화 조치를 하고 있습니다.
        </Content>
        <div style={{ marginTop: 15 }} />
        <Content>
          개인정보 접속기록의 보관 및 위,변조 방지를 위한 조치를 하고 있습니다.
        </Content>
        <div style={{ marginTop: 15 }} />
        <Content>
          개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고 개인정보나
          자료가 유출되거나 손상되지 않도록 방지하고 있습니다.
        </Content>
        <div style={{ marginTop: 15 }} />
        <Content>개인정보의 안전한 보관을 위한 조치를 하고있습니다.</Content>
        <div style={{ marginTop: 15 }} />
        <Content>
          개인정보가 포함된 출력, 복사물을 안전하게 관리하고 있습니다.
        </Content>
        <div style={{ marginTop: 15 }} />
        <Content>
          이용목적이 달성된 개인정보는 복구 및 재생이 불가능한 방법으로
          파기합니다.
        </Content>
        <div style={{ marginTop: 15 }} />
        <Content>
          개인정보보호 담당자를 지정하여 해당 업무에 노력을 하고 있습니다.
        </Content>
        <div style={{ marginTop: 15 }} />
        <Content>
          카보는 이용자 보호활동에 대해 국내 기관으로부터 검증을 받기위한
          활동들을 하고 있습니다.
        </Content>
      </div>

      <div style={{ borderTop: 'solid 5px #000', padding: '50px 0' }}>
        <Title>8. 개인정보 보호책임자 및 담당자 안내</Title>
        <div style={{ marginTop: 30 }} />
        <Content>
          카보는 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와
          같이 개인정보책임자 및 담당자를 지정하고 있습니다.
        </Content>
        <div style={{ marginTop: 10 }} />
        <div style={{ border: 'solid 1px #000', padding: 10 }}>
          <ContentBold>개인정보 보호 책임, 담당자</ContentBold>
          <div style={{ marginTop: 10 }} />
          <Content>이름: 이동진</Content>
          <Content>소속: 대표</Content>
          <Content>직위: 사내이사</Content>
          <Content>전화: 044-715-7442</Content>
          <Content>메일: 대표상담 번호를 통한 문의</Content>
        </div>
        <div style={{ marginTop: 10 }} />
        <Content>
          기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의
          가능합니다.
        </Content>
        <div style={{ marginTop: 10 }} />
        <Text>
          - 개인정보분쟁조정위원회 (국번없이) 1833-6972 (www.kopico.go.kr)
        </Text>
        <Text>- 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</Text>
        <Text>- 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</Text>
        <Text>- 경찰청 사이버수사국 (police.go.kr / 국번없이 182)</Text>
      </div>

      <div style={{ borderTop: 'solid 5px #000', padding: '50px 0' }}>
        <Title>9. 본 개인정보처리방침의 적용 범위</Title>
        <div style={{ marginTop: 30 }} />
        <Content>
          - 본 개인정보처리방침은 다이렉트 카보(https://service.cabo.kr)
          서비스에 적용됩니다.
        </Content>
      </div>

      <div style={{ borderTop: 'solid 5px #000', padding: '50px 0' }}>
        <Title>10. 개정 전 고지 의무</Title>
        <div style={{ marginTop: 30 }} />
        <Content>
          본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소
          7일 전에 ‘공지사항’을 통해 사전 공지를 할 것입니다.
        </Content>
        <Content>
          다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자
          권리의 중대한 변경이 발생한 때에는 최소 30일 전에 공지하며, 필요 시
          이용자 동의를 다시 받을 수도 있습니다.
        </Content>
        <div style={{ marginTop: 30 }} />
        <Text>- 공고일자: 2024년 08월 06일</Text>
        <Text>- 시행일자: 2024년 08월 13일</Text>
        <div style={{ marginTop: 30 }} />
        <a href="https://cabo.kr/pc/company/alarmBoard">
          이전 개인정보처리방침 전체 목록 보기
        </a>
      </div>
    </div>
  )
}
export default Private240806

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
`
const Content = styled.div`
  font-size: 16px;
  line-height: 22px;
`
const ContentBold = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
`
const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
`

const CustomTable = styled.table`
  margin: 10px 0;
  width: 100%;
  border-collapse: collapse;
`
const CustomTd = styled.td`
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
`
