import axios from 'axios'
import { encryptData } from './encryptData'

export const saveLog = async (log) => {
  
}

export const saveMail = async ({ name, phone, email, question }) => {
  const body = {
    name,
    phone,
    email,
    question,
  }
  await axios
    .post(
      `${process.env.REACT_APP_CABO_BACKEND}${process.env.REACT_APP_COMPANY_CONTRACT}`,
      { encryptedData: await encryptData(body) },
    )
    .then(() => {})
    .catch(() => {})
}
