const CaboInner = ({ children }) => {
  return (
    <div
      style={{
        padding: '0 25px',
        height: '100%',
      }}
    >
      {children}
    </div>
  )
}

export default CaboInner
